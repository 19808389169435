<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12 my-2">
        <h3>Refunds</h3>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <template v-if="account">
        <v-progress-circular
          v-if="localLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-row v-if="financialData">
          <v-col cols="12">
            <v-simple-table height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Status</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Customer</th>

                    <th class="text-left">Date</th>
                    <th class="text-left">Order ID</th>
                    <th class="text-left">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in financialData" :key="item.id">
                    <td>{{ item.status | capitalize }}</td>
                    <td>{{ item.amount | currency }}</td>
                    <td>{{ item.buyer ? item.buyer.name : '' }}</td>
                    <td>{{ item.updatedTime | formatDate }}</td>
                    <td>
                      <router-link :to="'/order/view/' + item.orderId">
                        {{
                          item.orderId
                            ? item.orderId.substr(0, 4)
                            : 'missing data'
                        }}
                      </router-link>
                    </td>
                    <td>{{ item.message }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            Setup your bank account using
            <a target="_blank" :href="callBackUrl">
              <v-btn color="blue lighten-2">
                <v-icon large left>fab fa-stripe</v-icon>
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12" v-if="false">
            <a target="_blank" :href="callBackUrlForCode">Link</a> if you have
            existing Stripe account.
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-alert outlined color="error" icon="warning" :value="true"
          >Your account is not verified yet.</v-alert
        >
      </v-col>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import JsonCSV from 'vue-json-csv'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    'download-csv': JsonCSV
  },
  data() {
    return {
      dates: [
        moment()
          .add(-1, 'w')
          .format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      monthData: {},
      menu: false,
      localLoading: false,
      year: '2020',
      financialData: [],
      monthlyData: {},
      months: [],
      selectedMonth: null,
      statuses: [
        'All',
        'Delivered',
        'Picked up',
        'Cancelled',
        'No Delivery',
        'No Pickup',
        'Rejected',
        'Failed',
        'Accepted'
      ],

      status: null
      /* January: null,
      February: null,
      March: null,
      April: null,
      May: null,
      June: null,
      July: null,
      August: null,
      September: null,
      October: null,
      November: null,
      December: null,*/
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      account: 'bankAccount'
    }),
    callBackUrl() {
      const domain = process.env.VUE_APP_DOMAIN
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://biz.${domain}/callback/account&client_id=${client_id}&state=${this.profile.id}`
    },
    callBackUrlForCode() {
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write&state=${this.profile.id}`
    },
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    monthRange() {
      return _.range(12).map(m => {
        return {
          month: moment(`${this.year}-${m + 1}`).format('MMMM'),
          endOf: moment(`${this.year}-${m + 1}`)
            .endOf('month')
            .format('YYYY-MM-DD'),
          startOf: moment(`${this.year}-${m + 1}`)
            .endOf('startOf')
            .format('YYYY-MM-DD')
        }
      })
    }
  },

  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.financialData = []
      this.localLoading = true
      this.financialData = await this.loadTransactions(this.dates)
      this.localLoading = false
    },
    async loadTransactions(dates) {
      let list = []
      if (!this.profile) {
        console.warn('Profile is null')
      }
      return await this.$store
        .dispatch('loadRefunds', { uid: this.profile.id, dates })
        .then(d => {
          this.localLoading = false
          if (d) {
            d.forEach(charge => {
              list.push(charge)
            })
          }
          console.log(`-----------------<`, list)
          return list
        })
        .catch(e => {
          this.localLoading = false
          console.log(e)
          this.$store.dispatch('setError', {
            message: 'Unable to load Payments'
          })
        })
    }
  }
}
</script>
